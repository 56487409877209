.AppsDiv {
    /* background-color: rgb(0, 0, 0); */
    /* position: static; */
    margin: auto;
    text-align: center;
    min-height: 1000px;
    color: white;
}

.card {
    padding: 20px;
    position: relative;
    margin: auto;
    /* background-color: white; */
    /* aspect-ratio: 1; */

    width: 40vw;
    height: 40vw;

    /* width: 500px; */
    /* height: 500px; */
    
    border-radius: 40px;
    /* box-shadow: tomato; */
    transform: scale(1);
    transition-duration: 150ms;
    /* box-shadow: 10px 12px #49303049; */
    box-shadow: 10px 12px #3a3a3a49;


}

.card:hover {
    transform: scale(1.1);
    box-shadow: 20px 12px rgb(43, 43, 43);

}

/* .card:hover, .words {
    color: red;

} */

.borderDiv {
    width: 80%;
    /* background-color: blue; */
    margin: auto;
}

.content {
    /* background-color: red; */
    display: flex;
    flex-direction: column; 
    /* row */
    /* float: center; */
    position: relative;
    margin: auto;
    text-align: center;


    /* min-height: 1000px; */
    /* color: white; */
    /* display: flex; */
    /* flex-direction: column; */

}

.subtitle {
    float: left;

}

.words {
    font-size: 30px;
    /* transition-property: all; */
    /* transition-duration: 100ms; */

}

.eightBall {
    /* position: absolute; */
    /* background-color: rgb(0, 0, 0); */
    background-color: blue;

    /* width: 175px; */
    /* height: 175px; */
    /* width: 20vw; */
    /* height: 20vw; */

    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: 8vw;
    padding-right: 8vw;
    border-radius: 50%;
    /* color: white; */
    color: red;

    font-size: 10vw;
    /* margin: 10px; */

    margin: auto;

    animation-name: ballShake;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-delay: 0ms;
}

.ticX {
    width: 15vw;
    height: 15vw;
    margin: auto;
    rotate: 45deg;


    /* animation-delay: 200ms; */
}

.ticLX {
    position: absolute;
    margin: auto;
    left: 9vw;
    /* padding-left: 50%; */
    width: 2vw;
    height: 15vw;
    background-color: blue;
    /* transform: rotate(-45deg); */
    rotate: 90deg;
    border-radius: 10%;
    transform-origin: center;


}
.ticRX {
    position: absolute;
    margin: auto;
    left: 9vw;


    width: 2vw;
    height: 15vw;
    background-color: blue;
    /* transform: rotate(45deg); */
    border-radius: 10%;

}

.ticO {
    /* position: absolute; */
    border: 10px solid rgb(255, 0, 0);

    /* background-color: blue; */
    /* width: 175px; */
    /* height: 175px; */
    width: 10vw;
    height: 10vw;

    border-radius: 50%;

    /* margin: 10px; */
    margin: auto;
/* 
    animation-name: ballbounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-delay: 200ms; */
}

.ball {
    /* position: absolute; */
    background-color: red;
    /* width: 175px; */
    /* height: 175px; */
    width: 12vw;
    height: 12vw;

    border-radius: 50%;
    /* margin: 10px; */
    margin: auto;

    animation-name: ballbounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-delay: 200ms;

}

.ball2 {
    /* position: absolute; */
    margin: auto;
    background-color: blue;
    /* width: 175px;
    height: 175px; */
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
    /* margin: 10px; */
    animation-name: ballbounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;


}

@keyframes ballbounce {
    0% {
        transform: translateY(0vw);
    }

    100% {
        transform: translateY(10vw);

    }

}

@keyframes shakeTicTac {
    0% {
        transform: translateY(0vw);

    }
    25% {
        transform: translateY(1vw);

    }

    50% {
        transform: translateY(0vw);

    }

    75% {
        transform: translateY(-1vw);

    }

    100% {
        transform: translateX(0vw);

    }

}

@keyframes ballShake {
    0% {
        transform: translateX(0vw);
    }

    50% {
        transform: translateX(2vw);

    }
    100% {
        transform: translateX(-2vw);

    }

}

.ballBox {
    padding-top: 5vw;
    position: relative;
    display: flex;
    flex-direction: row;
    /* float: center; */
    place-items: center;
    margin: auto;
    /* width: 400px; */
    /* height: 400px; */
    /* background-color: blueviolet; */
}

.ticBox {
    padding-top: 5vw;
    position: relative;
    display: flex;
    flex-direction: row;
    /* float: center; */
    place-items: center;
    margin: auto;
    /* width: 400px; */
    /* height: 400px; */
    /* background-color: blueviolet; */

    animation-name: shakeTicTac;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
}


.spinList {
    background-color: yellow;
    width: 400px;
    height: 200px;
    margin: auto;

    position: relative;
    /* display: grid; */
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* justify-items: center;
    align-items: center;  */

    /* place-items: center; */


}

.spinList2 {
    /* background-color: yellow; */
    /* width: 400px; */
    /* height: 200px; */
    margin: auto;
    width: 400px;
    height: 200px;
    position: relative;
    /* width: 12vw;
    height: 12vw; */
    /* display: grid; */
    display: flex;
    flex-direction: row;

    /* justify-content: center; */
    /* justify-items: center;*/
    /* align-items: center;   */

    /* place-items: center; */


}

.spinBox {
    position: absolute;

    /* margin: 0 auto; */
    
    /* justify-content: center;
    align-content: center; */
    width: 100px;
    height: 100px;
    border: 5px blue solid;
    background-color: red;
    border-radius: 20%;
    animation: spin;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    /* left: 100%; */
    top: 50px;
    left: 50px;
    /* transform: translate(-50%, -50%); */

}



/* .card:hover .spinBox3 {
    animation-play-state: running;

} */

.cardTitle {
    margin: auto;
    position: relative;
    /* float: left; */
    text-align: center;
    font-size: 80px;
    color: black;
    font-weight: bold;
    /* transition-property: all; */
    /* transition-duration: 150ms; */

    /* background-color: pink; */
}

.spinBox3 {
    margin: auto;
    /* margin: auto;     */
    margin-top: 3vw;
    /* width: 225px; */
    /* height: 225px; */
    width: 19vw;
    height: 19vw;

    border: 4vw blue solid;
    background-color: red;
    border-radius: 20%;
    animation: spin;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* animation-play-state: paused; */


}

.spinBox2 {
    position: absolute;

    
    /* justify-content: center;
    align-content: center; */
    width: 100px;
    height: 100px;
    border: 5px blue solid;
    background-color: red;
    border-radius: 20%;
    animation: spin;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* left: 100%; */
    top: 50px;
    left: 50px;
    /* transform: translate(-50%, -50%); */


}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }


    100% {
        transform: rotate(360deg);
    }
}