.MusicPage {
    /* background-color: black; */
    min-height: 1000px;
    color: white;
    position: relative;

}

.subtitle {
    padding: 15px;
    margin: auto;
    position: relative;

    /* padding-bottom: 100px; */
    font-size: 30px;
    font-weight: bolder;
    font-style: italic;
}
.backgroundText {
    position: absolute;
    z-index: -1;
    font-size: 100;
    opacity: 50%;
    width: 100%;
    height: 100%;

}