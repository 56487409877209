footer {
  width: 100%;
  height: 70px;
  background-color: black;
  margin: auto;
  /* overscroll-behavior; */
  /* place-items: center; */
  /* border-radius: 20px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* background-color: rgb(43, 43, 43); */

}

.socialBox {
  display: flex;
  flex-direction: row;
  margin: auto;
  place-items: center;
  width: 400px;
  height: 100%;

  /* background-color: red; */
}

.img {
  position: relative;
  color: black;
  margin: auto;
  font-size: 20px;
  /* font-size: 4vw; */

}

.linkSocial{
  position: relative;
  display: flex;
  margin: auto;
  /* place-items: center; */
  /* text-align: center; */
  text-decoration: none;
  color: black;
  /* background-color: black; */
    /* background-color: tomato; */

  border-radius: 50%;

  width: 40px;
  height: 40px;

  /* width: 4vw;
  height: 4vw; */
  /* font-size: ; */
  transition-duration: 250ms;
  transition-property: all;
  /* top: calc(50% - 25px); */

}

.linkSocial:hover {
  transform: scale(1.2);
  /* background-color: ; */
  /* mix-blend-mode: lighten; */
}