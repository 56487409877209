
.inputbox {
    /* background-color: black; */
    color: #00ff00;
    font-family: Menlo;
    width: 100%;
    border: 0px;
    /* text-decoration-color: black; */
}

/* .inputbox:focus {
    border: 0px;
    color: 'red';

} */

.inputbox:focus {
    border: none;
    outline: none;
}
