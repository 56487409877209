@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

.Home {
    /* background-color: rgb(0, 0, 0); */
    min-height: 1000px;
    color: white;
    margin: auto;
    font-family: 'Menlo', 'Roboto Mono';
    color: #00ff00;
    letter-spacing: 1px;
    /* line-height: 100px; */
    /* font-family: 'Arbutus Slab', serif; */
    /* transition-duration: 1s; */
    /* transition-property: all; */
    /* opacity: 1; */
}

.project {
    /* background-color: white; */
    color: #00ff00;
    width: 60vw;
    /* height: 60vw; */
    /* box-shadow: 3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(255,0,80,0.3), 0 0 3px; */

    /* box-shadow: 10px -10px 5px 2px #00ff00; */
    border-bottom: 10px solid #00ff00;
    border-top: 10px dotted #00ff00;
    /* border-left: 10px dotted #00ff00;
    border-right: 10px dotted #00ff00; */

    border-radius: 0;
    margin: auto;
    padding: 2vw;
    text-align: center;
}
/* 
.project:hover {
   transform: scale(1.1);
} */

.spookyline {
    background-color: black;
    width: 20px;
    height: 50px;
    border-radius: 20px;

    animation: movespooky;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-duration: 2s;
    animation-iteration-count:infinite ;
}

.spookyline2 {
    background-color: black;
    width: 20px;
    height: 20px;
    border-radius: 20px;

    animation: movespooky2;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-duration: 2s;
    animation-iteration-count: infinite ;
}
@keyframes movespooky2 {
    0% {
        background-color:rgb(77, 109, 255); 
        transform: translateY(-70vw);
        height: 70px;

    }

    100% {
        background-color:pink; 
        height: 20px;
        transform: translateY(70vw);
    }
}

@keyframes movespooky {
    0% {
        background-color:rgb(77, 109, 255); 
        transform: translateY(70vw);
        height: 20px;

    }

    100% {
        background-color:pink; 
        height: 70px;
        transform: translateY(-70vw);
    }
}
/* .Home::after {
    opacity: 0;
} */

.texttop {
    /* display: flex; */
    /* flex: auto; */
    width: 100%;
    /* font-size: 2vw; */
    /* font-weight: bold; */
    /* text-align: left; */
    /* align-content: flex-start; */
    
    /* margin-top: 2vw; */
}

h2 {
    font-size: 30px;
    width: 90%;
    color: #00ff00;
    padding-left: 20px;
    /* text-align: left; */
}

.topbox {
    display: block;
    position: relative;
    /* margin: auto; */

    /* text-align: center; */
}

.subTitle {
    padding: 10px;
    color: #00ff00;
}

/* .emmabox {
    position: relative;
    color:rgb(77, 109, 255);
    left:-40px;

    animation-name: cool;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
} */

@keyframes cool {
    0% {
        color:rgb(77, 109, 255); 
        left:-40px;

    }

    100% {
        color:rgb(217, 32, 241);
        left: 40px;
    }
}

.boxlist {
    align-items: center;
    display: flex;
    /* margin: auto; */
    /* list-style: none;
    flex-direction: column;
    justify-content: center;
    align-content: center; */
}

.spinBox {
    margin: auto;
    position: relative;
    
    width: 100px;
    height: 100px;
    /* border: 50px; */


    /* padding-top: 100px; */


}

.box {

    background-color: white;
    width: 100px;
    height: 100px;
    position: absolute;
    margin: auto;
    /* float: left; */
    left: calc(50% - 53px) ;
    top: calc(50% - 53px) ;

    /* outline-width: 0px;
    outline-color: blue;
    outline-style: auto; */

    border: 6px solid blue;

    border-radius: 20%;
    animation: spin;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    transition-property: all;
    transition-duration: 0.5s;
    transform: scale(1);

}



.bigBox:hover .box{
    background-color: red;
    border-radius: 50%;
    transform: scale(2);
        /* width: 120px; */
        /* height: 120px; */


}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }


    100% {
        transform: rotate(360deg);
    }
}
.bigBox {
    margin: auto;
    /* justify-content: center;
    align-items: center; */
    place-items: center;
    position: relative;
    text-align: center;
    margin: auto;
    /* background-color: pink; */
    width: 120px;
    height: 120px;
    /* width: auto; */

}

.bigBox .textBox {
    position: absolute;
    margin: auto;
    font-size: 15px;
    /* text-align: center; */
    
    color: black;
    
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    /* background-color: yellow; */
}
.bigBox .textBox span {
    /* position: absolute;
    margin: auto;
    font-size: 15px;
    text-align: center;

    color: black;

    width: 70px;
    height: 70px;
    top: 20px;
    left: 20px;
    background-color: yellow; */
    /* left: 50%; */
    /* top: %; */
    position: relative;
    display: inline-block;
    /* background-color: red; */
    /* top: 50%; */
    margin: auto;
    /* color: blue; */
    
    text-align: center;
    /* place-content: center; */
}


.box2 {
    background-color: white;
    width: 200px;
    height: 200px;
    position: relative;
    animation-name: bouncy, updown;
    margin: auto;
    animation-duration: 4s, 4s;
    animation-iteration-count: infinite, infinite;
    animation-direction: alternate, normal;
    animation-timing-function: cubic-bezier(), linear;


}

/* @keyframes updown {
    0% {
        top: 0px;
        transform: rotate(0deg);

    }

    50% {
        top: 100px;

    }

    100% {
        top: 0px;
        transform: rotate(360deg);

    }
}

@keyframes bouncy {
    0% {
        border-top-left-radius: 0%;
        background-color: white;

    }

    25% {
        border-top-left-radius: 50%;
        border-top-right-radius: 0%;

    }

    50% {
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 0%;

    }

    75% {
        border-bottom-left-radius: 50%;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 0%;

    }

    100% {
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        background-color: red;

    }
} */


.spinBx {
    margin: auto;
    /* margin-top: 3vw; */
    /* width: 225px; */
    /* height: 225px; */
    width: 50px;
    height: 50px;
    

    border: 2px #00ff00 solid;
    background-color: transparent;
    border-radius: 2px;
    animation: spin;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* animation-play-state: paused; */

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }


    100% {
        transform: rotate(360deg);
    }
}

