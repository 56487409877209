.TopNav {
  /* position: fixed;  */
  /* top: 0;  */
  
  display: flex;
  /* width: 80%; */
  height: 100px;
  margin: auto;
  /* float: left; */
  /* flex-direction: row; */
  /* font-size: calc(10px + 2vmin); */

  /* overflow: hidden; */
  /* left: 100px; */
  color: #00ff00;
  /* align-items: flex-start; */
  /* flex: auto; */
  z-index: 3;
  font-family: Menlo;

  border-bottom: 2px solid #00ff00;
  /* border-style: solid; */
  /* font-family: 'Arbutus Slab', serif; */
  /* float: left; */

}

.NavItem {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  transition: ease-in-out 150ms;
  text-decoration-line: none;

  /* font-weight: bold; */
  text-align: center;
  /* color: rgb(204, 204, 204); */
  color: #00ff00;

  font-size: 20px;
  /* width: 10vw; */
  /* height: 2vw; */

  /* font-size: medium; */

}

.bigSelNavItem {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  transition: ease-in-out 150ms;
  /* text-decoration-line: none; */
  /* transform: scale(1.2); */
  color: #00ff00;

  /* color: white; */
  text-align: center;
  /* font-size: medium; */
  /* font-weight: bold; */
  /* border:  #00ff00;
  border-style: solid;
  border-width: 2px; */

  font-size: 20px;
  /* width: 10vw; */

}

/* .NavBtn {
  border:  #00ff00;
  border-style: solid;
  border-width: 10px;

} */
  

.NavItem:hover {
  text-decoration-line: underline;


} 

 /* .NavItem:hover { */
  /* background-color: rgba(255, 255, 255, 0.212); */
  /* transform: scale(1.1); */
  /* transition: ease-in-out 150ms; */
/* }  */

/* .navItemText {
  text-align: center;
  color: white;
  font-size: medium;

} */


.Tabs {
  /* position: absolute; */
  display: flex;

  /* margin: auto; */
  /* display: flex; */
  /* align-items: center; */
  flex-direction: row;
  /* justify-content: space-evenly; */
  /* align-items: space-evenly; */
  place-items: center;
  /* font-size: 25px; */
  width: 200px;
  float: left;
  /* background-color: red; */

  /* padding-left: 100px; */

}

/* .FillBar {
  background-color: black;
  height: 5px;
  width: 0%;
  transition: ease-in-out 150ms;
} */

/* .NavBtn:hover { */
  /* height: 5px; */

  /* width: 80%; */
  /* transition: ease-in-out 150ms; */
/* } */
